.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.custom-modal {
  position: relative;
  width: 80%;
  max-width: 800px;
  background-color: white;
  border-radius: 5px;
}
.custom-modal-header{  
  color: #fff;
  background: #006AD3 !important;
  padding: 20px;
}

.custom-modal-body{
  padding: 20px;
}

.custom-modal .close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;  
  width: 20px;
  height: 20px;
  margin: 20px;
}

.custom-modal .custom-modal-content {
  margin-top: 20px;
}


.custom-z-index{
  z-index: 99999 !important;
}
.dx-overlay-wrapper.dx-popup-wrapper.dx-dropdowneditor-overlay {
  z-index: 9999 !important;
}
