.Main_sidebar{
    background-color: rgb(218, 216, 216);
    width: 20%;
    height: 100%;
    float: left;
}
.Func_sidebar{
    background-color: rgba(255, 255, 255, 0.651);
}
.option_list
{
 background-color: rgba(227, 225, 225, 0.651);
}
.toggles{
    display: flex;
    flex-direction: row;
}