
    .my-custom-scrollbar {
        position: relative;
        height: 200px;
        overflow: auto;
      }
  
      .table-wrapper-scroll-y {
        display: block;
        width: 100%;
      }
      .suggestion{
        cursor: pointer;
      }
      .suggestion.active{
        background-color: #1764E7;
        color: #fff;
      }
      .suggestion:hover{
        background-color: #1764E7;
        color: #fff;
      }