button{
    border: none;
    background: #FF6948;
    color: #fff;
    border-radius: 5px;
    border-radius: 0px;
    width: 100%;
    height: 30px;
    margin-bottom: 5px;
    height: 42px;
}

button:hover{
    background: #ff5e3a;
    color: #fff;
}
